.radio[type='radio'] {
  @apply appearance-none inline-block cursor-pointer leading-4 outline-none align-top absolute left-0 w-5 h-5 top-[22px];
}

.radio[type='radio']:before,
.radio[type='radio']:after {
  @apply absolute left-0 top-0 w-5 h-5 rounded-full -translate-y-1/2 content-[''];
}

.radio[type='radio']:before {
  @apply shadow-focus shadow-white;
}

.radio[type='radio'].with-green-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-green-lightMode-primary-hover;
}
.radio[type='radio'].with-blue-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-blue-dark;
}
.radio[type='radio'].with-salmon-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-salmon-lightMode-primary-hover;
}
.radio[type='radio'].with-purple-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-purple-lightMode-primary-hover;
}
.radio[type='radio'].with-lavender-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-lavender-lightMode-primary-hover;
}
.radio[type='radio'].with-yellow-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-yellow-dark;
}
.radio[type='radio'].with-turquoise-border:before {
  @apply top-[30px] left-5 shadow-focus shadow-profile-turquoise-dark;
}

.radio[type='radio'].error:before {
  @apply shadow-focus shadow-darkMode-red;
}

.radio[type='radio']:hover:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.3);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio']:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.6);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.4);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: Normal;
  animation-timing-function: ease-in;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio']:checked:before {
  --color-one: hsla(var(--base-color-blue), 63%, 1);
  --color-two: hsla(var(--base-color-medium-gray), 18%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].withGrayBg:checked:before {
  /* --color-one: hsla(var(--base-color-blue), 63%, 1); */
  --color-two: hsla(var(--base-color-white), 27%, 1);
}

.radio[type='radio']:checked:hover:before {
  --color-one: hsla(var(--base-color-blue), 63%, 0.3);
  --color-two: hsla(var(--base-color-blue), 63%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: Normal;
  animation-timing-function: ease-in;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio']:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-blue), 63%, 0.6);
  --color-two: hsla(var(--base-color-blue), 63%, 1);
  --color-three: hsla(var(--base-color-medium-gray), 18%, 0.4);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: Normal;
  animation-timing-function: ease-in;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-purple-bg:checked:before {
  --color-one: hsla(var(--base-color-dark-gray), 10%, 1);
  --color-two: hsla(var(--base-profile-color-purple), 64%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-salmon-bg:checked:before {
  --color-one: hsla(var(--base-color-dark-gray), 10%, 1);
  --color-two: hsla(var(--base-profile-color-salmon), 81%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-yellow-bg:checked:before {
  --color-one: hsla(var(--base-color-dark-gray), 10%, 1);
  --color-two: hsla(var(--base-profile-color-yellow), 75%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-turquoise-bg:checked:before {
  --color-one: hsla(var(--base-color-dark-gray), 10%, 1);
  --color-two: hsla(var(--base-profile-color-turquoise), 87%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-lavender-bg:checked:before {
  --color-one: hsla(var(--base-color-dark-gray), 10%, 1);
  --color-two: hsla(var(--base-profile-color-lavender), 90%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-green-bg:checked:before {
  --color-one: hsla(var(--base-color-white), 100%, 1);
  --color-two: hsla(var(--base-profile-color-green), 50%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-blue-bg:checked:before {
  --color-one: hsla(var(--base-color-white), 100%, 1);
  --color-two: hsla(var(--base-profile-color-blue), 80%, 1);

  box-shadow:
    inset 0 0 0 2px var(--color-one),
    inset 0 0 0 5px var(--color-two),
    inset 0 0 0 10px var(--color-one);
}

.radio[type='radio'].with-green-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.3);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-profile-color-green), 50%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-green-focus:focus-visible:before,
.radio[type='radio'].with-green-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.5);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-profile-color-green), 50%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-blue-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.3);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-profile-color-blue), 72%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-blue-focus:focus-visible:before,
.radio[type='radio'].with-blue-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 100%, 0.5);
  --color-two: hsla(var(--base-color-white), 100%, 1);
  --color-three: hsla(var(--base-profile-color-blue), 72%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-lavender-checked-hover:checked:hover:before,
.radio[type='radio'].with-lavender-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.3);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-lavender), 90%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-lavender;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-lavender-focus:focus-visible:before,
.radio[type='radio'].with-lavender-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.5);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-lavender), 90%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-lavender;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-turquoise-checked-hover:checked:hover:before,
.radio[type='radio'].with-turquoise-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.3);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-turquoise), 87%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-turquoise;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-turquoise-focus:focus-visible:before,
.radio[type='radio'].with-turquoise-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.5);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-turquoise), 87%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-turquoise;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-purple-checked-hover:checked:hover:before,
.radio[type='radio'].with-purple-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.3);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-purple), 64%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-purple;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-purple-focus:focus-visible:before,
.radio[type='radio'].with-purple-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.5);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-purple), 64%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-purple;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-yellow-checked-hover:checked:hover:before,
.radio[type='radio'].with-yellow-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.3);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-yellow), 75%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-yellow;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-yellow-focus:focus-visible:before,
.radio[type='radio'].with-yellow-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.5);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-yellow), 75%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-yellow;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-salmon-checked-hover:checked:hover:before,
.radio[type='radio'].with-salmon-hover:hover:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.3);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-salmon), 81%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-salmon;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

.radio[type='radio'].with-salmon-focus:focus-visible:before,
.radio[type='radio'].with-salmon-focus:checked:focus-visible:before {
  --color-one: hsla(var(--base-color-white), 1%, 0.5);
  --color-two: hsla(var(--base-color-white), 1%, 1);
  --color-three: hsla(var(--base-profile-color-salmon), 81%, 0.7);

  animation-duration: 0.2s;
  animation-name: expand-radio-salmon;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  box-shadow:
    0 0 0 8px var(--color-one),
    inset 0 0 0 2px var(--color-two),
    inset 0 0 0 5px var(--color-three),
    inset 0 0 0 10px var(--color-two);
}

@media (prefers-color-scheme: light) {
  .radio[type='radio']:before {
    @apply shadow-black;
  }

  .radio[type='radio'].error:before {
    @apply shadow-lightMode-red;
  }

  .radio[type='radio']:hover:before {
    --color-one: hsla(var(--base-color-white), 0%, 0.3);
    --color-two: hsla(var(--base-color-white), 0%, 1);
    --color-three: hsla(var(--base-color-white), 100%, 0.7);
  }

  .radio[type='radio']:focus-visible:before {
    --color-one: hsla(var(--base-color-white), 0%, 0.6);
    --color-two: hsla(var(--base-color-white), 0%, 1);
    --color-three: hsla(var(--base-color-white), 100%, 0.4);
  }

  .radio[type='radio']:checked:before {
    --color-one: hsla(var(--base-color-blue), 63%, 1);
    --color-two: hsla(var(--base-color-white), 100%, 1);
  }

  .radio[type='radio'].withGrayBg:checked:before {
    --color-one: hsla(var(--base-color-blue), 63%, 1);
    --color-two: hsla(var(--base-color-white), 91%, 1);
  }

  .radio[type='radio']:checked:hover:before {
    --color-one: hsla(var(--base-color-blue), 63%, 0.3);
    --color-two: hsla(var(--base-color-blue), 63%, 1);
    --color-three: hsla(var(--base-color-white), 100%, 0.7);
  }

  .radio[type='radio']:checked:focus-visible:before {
    --color-one: hsla(var(--base-color-blue), 63%, 0.6);
    --color-two: hsla(var(--base-color-blue), 63%, 1);
    --color-three: hsla(var(--base-color-white), 100%, 0.4);
  }

  .radio[type='radio'].with-blue-hover:hover:before {
    --color-one: hsla(var(--base-color-white), 100%, 0.3);
    --color-two: hsla(var(--base-color-white), 100%, 1);
    --color-three: hsla(var(--base-profile-color-blue), 72%, 0.7);

    animation-duration: 0.2s;
    animation-name: expand-radio;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-in-out;

    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  .radio[type='radio'].with-green-hover:hover:before {
    --color-one: hsla(var(--base-color-white), 100%, 0.3);
    --color-two: hsla(var(--base-color-white), 100%, 1);
    --color-three: hsla(var(--base-profile-color-green), 50%, 0.7);

    animation-duration: 0.2s;
    animation-name: expand-radio;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-in-out;

    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}

@keyframes expand-radio {
  0% {
    @apply shadow-focus shadow-white;
  }

  25% {
    box-shadow:
      0 0 0 3px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow:
      0 0 0 5px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow:
      0 0 0 7px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}

@keyframes expand-radio-purple {
  0% {
    @apply shadow-focus shadow-profile-purple-lightMode-primary-hover;
  }

  25% {
    box-shadow:
      0 0 0 3px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow:
      0 0 0 5px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow:
      0 0 0 7px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}

@keyframes expand-radio-salmon {
  0% {
    @apply shadow-focus shadow-profile-salmon-lightMode-primary-hover;
  }

  25% {
    box-shadow:
      0 0 0 3px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow:
      0 0 0 5px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow:
      0 0 0 7px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}

@keyframes expand-radio-lavender {
  0% {
    @apply shadow-focus shadow-profile-lavender-lightMode-primary-hover;
  }

  25% {
    box-shadow:
      0 0 0 3px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow:
      0 0 0 5px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow:
      0 0 0 7px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}

@keyframes expand-radio-turquoise {
  0% {
    @apply shadow-focus shadow-profile-turquoise-dark;
  }

  25% {
    box-shadow:
      0 0 0 3px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow:
      0 0 0 5px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow:
      0 0 0 7px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}

@keyframes expand-radio-yellow {
  0% {
    @apply shadow-focus shadow-profile-yellow-dark;
  }

  25% {
    box-shadow:
      0 0 0 3px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  55% {
    box-shadow:
      0 0 0 5px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  85% {
    box-shadow:
      0 0 0 7px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }

  100% {
    box-shadow:
      0 0 0 8px var(--color-one),
      inset 0 0 0 2px var(--color-two),
      inset 0 0 0 5px var(--color-three),
      inset 0 0 0 10px var(--color-two);
  }
}
